<template>
  <div>
    <span class="text-center table-edit-icon" @click="showModal">
      <i class="text-center material-icons">edit</i>
    </span>
    <div class="centerx">
      <vs-popup
        :id="'popmodal' + params.data.tds_id"
        class="holamundo editModal"
        title="Update TDS"
        :active.sync="popupActive"
      >
        <form>
          <FocusLock>
            <div class="vx-row">
              <div class="col-12">
                <div class="vx-row mb-6">
                  <div
                    class="col-12 col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">TDS Name*</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="TDS Name"
                        v-model="form.tds_name"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('TDS Name')"
                        >{{ errors.first("TDS Name") }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="vx-row mb-6">
                  <div
                    class="col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">TDS Percentage*</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        v-model="form.tds_percentage"
                        class="w-full"
                        name="TDS Percentage"
                        type="number"
                        step="0.01  "
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('TDS Percentage')"
                        >{{ errors.first("TDS Percentage") }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col mx-auto">
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3"
                  >Update</vs-button
                >
              </div>
            </div>
          </FocusLock>
        </form>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import MasterService from "@/services/MasterService.js";
export default Vue.extend({
  data() {
    return {
      switch1: true,
      submitStatus: true,
      form: {
        tds_name: "",
        tds_percentage: "",
        tds_id: "",
        tds_isActive: "1",
      },

      popupActive: false,
      params: null,
      value: null,
      count: 0,
    };
  },
  watch: {
    form: {
      handler: function(newVal, oldVal) {
        if (this.count > 0) {
          this.submitStatus = false;
        }
        this.count++;
      },
      deep: true,
    },
  },
  beforeMount() {
    this.value = this.params.value;
    this.form = this.params.data;
  },
  beforeDestroy() {
    let _id = document.getElementById("popmodal" + this.params.data.tds_id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    showModal() {
      this.popupActive = true;
    },
    submitForm() {
      this.$validator.validateAll().then((result) => {
        this.$vs.loading();
        if (result) {
          this.submitStatus = true;
          let id = this.form.tds_id;
          let payload = {
            tds_name: this.form.tds_name,
            tds_id: this.form.tds_id,
            tds_percentage: this.form.tds_percentage,
            tds_isActive: this.params.data.tds_isActive,
          };

          MasterService.updateTDS(payload, id)
            .then((response) => {
              this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              this.popupActive = false;
              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Updated!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                setTimeout(() => {
                  eventBus.$emit("refreshTabletds", data);
                }, 1);
                this.clearForm();
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.submitStatus = true;
            });
        }
      });
    },

    clearForm: function() {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.$validator.reset();
    },
  },
});
</script>
